import axios from 'axios';

const tokenHeader = localStorage.getItem('Header');
const tokenPayload = localStorage.getItem('payload');
const tokenSignature = localStorage.getItem('sig');


const token = `${tokenHeader}.${tokenPayload}.${tokenSignature}`;

export const UserService = {

    getAllUsers:(page) =>{
        // Make an API call to get the card details
        return axios.get(`https://api.paytaps.com/admin/user/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    }


};
import axios from 'axios';

const tokenHeader = localStorage.getItem('Header');
const tokenPayload = localStorage.getItem('payload');
const tokenSignature = localStorage.getItem('sig');


const token = `${tokenHeader}.${tokenPayload}.${tokenSignature}`;

export const ParamaterService = {

    getAllParams:(page) =>{
        // Make an API call to get the card details
        return axios.get(`https://api.paytaps.com/admin/parameter/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    addParameter:(data) =>{
        // Make an API call to get the card details
        return axios.post(`https://api.paytaps.com/admin/parameter/add`, data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    editParameter:(id,data) =>{
        // Make an API call to get the card details
        return axios.patch(`https://api.paytaps.com/admin/parameter/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    }
};
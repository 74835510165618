import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { useNavigate } from 'react-router-dom';
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Status',
		Footer : 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {
			let badgeClass = '';
			switch (value) {
				case 'Pending':
					badgeClass = 'badge-warning'; // Customize as needed
					break;
				case 'Success':
					badgeClass = 'badge-success'; // Customize as needed
					break;
				case 'Declined':
					badgeClass = 'badge-danger'; // Customize as needed
					break;
				default:
					badgeClass = 'badge-secondary'; // Default badge
			}
			return (
				<span className={`badge ${badgeClass}`}>
					{value}
				</span>
			);
		},
	},
	{
		Header : 'Amount Fiat',
		Footer : 'Amount Fiat',
		accessor: 'amountFiat',
		Filter: ColumnFilter,
	},
	{
		Header : 'Amount Crypto Total',
		Footer : 'Amount Crypto Total',
		accessor: 'amountCryptoTotal',
		Filter: ColumnFilter,
	},
	{
		Header : 'Email Id',
		Footer : 'Email',
		accessor: 'user.email',
		Filter: ColumnFilter,
	},

	{
		Header : 'Created at',
		Footer : 'Created at',
		accessor: 'createdAt.date',
		Cell: ({ value }) => {return format(new Date(value), 'yyyy-MM-dd H:m:s')},
		Filter: ColumnFilter,
	},

    {
        Header: 'Action',
        Footer: 'Action',
        accessor: 'Id', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const handleShow = () => {
                navigate(`/top-request-details/${row.original.id}`);
            };

            return (
                <div>
                    <button className="btn btn-primary" onClick={handleShow}>Show</button>
                </div>
            );
        },
        Filter: ColumnFilter,
    },

]



export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]
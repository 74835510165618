import axios from 'axios';

const tokenHeader = localStorage.getItem('Header');
const tokenPayload = localStorage.getItem('payload');
const tokenSignature = localStorage.getItem('sig');


const token = `${tokenHeader}.${tokenPayload}.${tokenSignature}`;

export const CardRequestService = {

    getAllCardRequest: (page) => {
        // Make an API call to get the card details
        return axios.get(`https://api.paytaps.com/admin/card-request/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    showCardRequestById: (Id) => {
        return axios.get(`https://api.paytaps.com/admin/card-request/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },


    editCardRequestById(id,cardData) {
        // Make an API call to get the card details
        return axios.patch(`https://api.paytaps.com/admin/card-request/edit/${id}`, cardData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    // approveCardRequest(id,cardData){
    //     return axios.patch(`https://api.paytaps.com/admin/card-request/status/${id}`, cardData, {
    //         headers: {
    //             Authorization: `Bearer ${token}` // Set the Authorization header
    //         }
    //     }); 
    // }
    approveCardRequest(id,cardData){
        return axios.post(`https://api.paytaps.com/admin/card/create/${id}`, cardData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        }); 
    }

};
import axios from 'axios';

const tokenHeader = localStorage.getItem('Header');
const tokenPayload = localStorage.getItem('payload');
const tokenSignature = localStorage.getItem('sig');


const token = `${tokenHeader}.${tokenPayload}.${tokenSignature}`;

export const LoadRequestService = {

    getAllLoadRequest(page) {
        // Make an API call to get the card details
        return axios.get(`https://api.paytaps.com/admin/topup-request/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    showLoadRequestById: (Id) => {
        return axios.get(`https://api.paytaps.com/admin/topup-request/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    approveLoadRequestById: (Id, data) => {
        return axios.patch(`https://api.paytaps.com/admin/topup-request/status/${Id}`,data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    editLoadRequestById(id,loadData) {
        // Make an API call to get the card details
        return axios.patch(`https://api.paytaps.com/admin/topup-request/edit/${id}`, loadData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    }


    
};
export const MenuList = [
    //Dashboard
    // {
    //     title: 'Dashboard',	
    //     classsChange: 'mm-collapse',		
    //     iconStyle: <i className="flaticon-381-networking"></i>,
    //     content: [
    //         {
    //             title: 'Dashboard',
    //             to: '/dashboard',					
    //         },
    //         // {
    //         //     title: 'Dashboard Dark',
    //         //     to: 'dashboard-dark',
    //         // },            
    //         {
    //             title: 'My Wallet',
    //             to: '/my-wallet',
    //         },
    //         {
    //             title: 'Invoices',
    //             to: '/invoices',
    //         },
    //         {
    //             title: 'Cards Center',
    //             to: '/cards-center',                
    //         },
    //         {
    //             title: 'Transactions',
    //             to: '/transactions',
                
    //         },
    //         {
    //             title: 'Transactions Details',
    //             to: '/transactions-details',                
    //         },			
	// 		{
    //             title: 'Task',
    //             to: '/task',                
    //         },    
    //     ],
    // },
    {
        title: 'Cards',	
        classsChange: 'mm-collapse',		
        iconStyle: <i className="fa fa-credit-card"></i>,
        content: [
            {
                title: 'Card request list',
                to: '/Cardrequest',					
            },    
            {
                title: 'Top Up request list',
                to: '/top-up-request',					
            },        
        ],
    },
    //Apps
    {
        title: 'Wallet',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="fa fa-wallet"></i>,
        content: [
            {
                title: 'Client Deposit Request',
                to: '/client-deposit-request'
            },
            {
                title: 'Client Wallet',
                to: '/client-wallet'
            },
            // {
            //     title: 'Edit Profile',
            //     to: 'edit-profile'
            // },
            {
                title: 'Admin Wallet',
                to: '/admin-wallet'
            }
        ],
    },
    {
        title: 'Users',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-user"></i>,
        content: [
            {
                title: 'Users list',
                to: '/Users-list'
            }
        ],
    },
    {
        title: 'Parameter',	
        classsChange: 'mm-collapse',
        iconStyle: <i className="flaticon-381-settings"></i>,
        content: [
            {
                title: 'Parameter',
                to: '/Params'
            }
        ],
    },
    //Charts
    // {
    //     title: 'Charts',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-controls-3"></i>,
    //     content: [
            
    //         {
    //             title: 'RechartJs',
    //             to: '/chart-rechart',					
    //         },
    //         {
    //             title: 'Chartjs',
    //             to: '/chart-chartjs',					
    //         },
    //         {
    //             title: 'Sparkline',
    //             to: '/chart-sparkline',					
    //         },
    //         {
    //             title: 'Apexchart',
    //             to: '/chart-apexchart',					
    //         },
    //     ]
    // },
    // //Boosttrap
    // {
    //     title: 'Bootstrap',	
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-internet"></i>,	
    //     content: [
    //         {
    //             title: 'Accordion',
    //             to: '/ui-accordion',					
    //         },
    //         {
    //             title: 'Alert',
    //             to: '/ui-alert',					
    //         },
    //         {
    //             title: 'Badge',
    //             to: '/ui-badge',					
    //         },
    //         {
    //             title: 'Button',
    //             to: '/ui-button',					
    //         },
    //         {
    //             title: 'Modal',
    //             to: '/ui-modal',					
    //         },
    //         {
    //             title: 'Button Group',
    //             to: '/ui-button-group',					
    //         },
    //         {
    //             title: 'List Group',
    //             to: '/ui-list-group',					
    //         },
    //         {
    //             title: 'Cards',
    //             to: '/ui-card',					
    //         },
    //         {
    //             title: 'Carousel',
    //             to: '/ui-carousel',					
    //         },
    //         {
    //             title: 'Dropdown',
    //             to: '/ui-dropdown',					
    //         },
    //         {
    //             title: 'Popover',
    //             to: '/ui-popover',					
    //         },
    //         {
    //             title: 'Progressbar',
    //             to: '/ui-progressbar',					
    //         },
    //         {
    //             title: 'Tab',
    //             to: '/ui-tab',					
    //         },
    //         {
    //             title: 'Typography',
    //             to: '/ui-typography',					
    //         },
    //         {
    //             title: 'Pagination',
    //             to: '/ui-pagination',					
    //         },
    //         {
    //             title: 'Grid',
    //             to: '/ui-grid',					
    //         },
    //     ]
    // },
    // //plugins
    // {
    //     title:'Plugins',
    //     classsChange: 'mm-collapse',
    //     iconStyle : <i className="flaticon-381-heart"></i>,
    //     content : [
    //         {
    //             title:'Select 2',
    //             to: '/uc-select2',
    //         },
    //         // {
    //         //     title:'Noui Slider',
    //         //     to: 'uc-noui-slider',
    //         // },
    //         {
    //             title:'Sweet Alert',
    //             to: '/uc-sweetalert',
    //         },
    //         {
    //             title:'Toastr',
    //             to: '/uc-toastr',
    //         },
    //         {
    //             title:'Jqv Map',
    //             to: '/map-jqvmap',
    //         },
    //         {
    //             title:'Light Gallery',
    //             to: '/uc-lightgallery',
    //         },
    //     ]
    // },
    // //Widget
    // {   
    //     title:'Widget',
    //     //classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-settings-2"></i>,
    //     to: '/widget-basic',
    // },
    // //Forms
    // {
    //     title:'Forms',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-notepad"></i>,
    //     content : [
    //         {
    //             title:'Form Elements',
    //             to: '/form-element',
    //         },
    //         {
    //             title:'Wizard',
    //             to: '/form-wizard',
    //         },
    //         {
    //             title:'CkEditor',
    //             to: '/form-ckeditor',
    //         },
    //         {
    //             title:'Pickers',
    //             to: '/form-pickers',
    //         },
    //         {
    //             title:'Form Validate',
    //             to: '/form-validation',
    //         },

    //     ]
    // },
    // //Table
    // {
    //     title:'Table',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-network"></i>,
    //     content : [
    //         {
    //             title:'Table Filtering',
    //             to: '/table-filtering',
    //         },
    //         {
    //             title:'Table Sorting',
    //             to: '/table-sorting',
    //         },
    //         {
    //             title:'Bootstrap',
    //             to: '/table-bootstrap-basic',
    //         },
           

    //     ]
    // },
    // //Pages
    // {
    //     title:'Pages',
    //     classsChange: 'mm-collapse',
    //     iconStyle: <i className="flaticon-381-layer-1"></i>,
    //     content : [
    //         {
    //             title:'Error',
    //             hasMenu : true,
    //             content : [
    //                 {
    //                     title: 'Error 400',
    //                     to : '/page-error-400',
    //                 },
    //                 {
    //                     title: 'Error 403',
    //                     to : '/page-error-403',
    //                 },
    //                 {
    //                     title: 'Error 404',
    //                     to : '/page-error-404',
    //                 },
    //                 {
    //                     title: 'Error 500',
    //                     to : '/page-error-500',
    //                 },
    //                 {
    //                     title: 'Error 503',
    //                     to : '/page-error-503',
    //                 },
    //             ],
    //         },
    //         {
    //             title:'Lock Screen',
    //             to: '/page-lock-screen',
    //         },

    //     ]
    // },
    
]
import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';

export function signUp(email, password) {
    //axios call
    // const postData = {
    //     email,
    //     password,
    //     returnSecureToken: true,
    // };
    // return axios.post(
    //     `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    //     postData,
    // );
}

export function login(email, password) {
    const postData = {
        email,
        password,
        // returnSecureToken: true,
    };
    return axios.post(
        `https://api.paytaps.com/admin/login`,
        postData,
    );
}

export function formatError(errorResponse) {
    console.log(errorResponse);
    switch (errorResponse.message) {
        case 'Invalid credentials':
            //return 'Invalid Password';
            swal("Oops", "Invalid Email or Password", "error",{ button: "Try Again!",});
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    const token = tokenDetails.token;
    const [header, payload, signature] = token.split('.');

    localStorage.setItem('Header', header);
    localStorage.setItem('payload', payload);
    localStorage.setItem('sig', signature);

}

export function checkAutoLogin(dispatch, navigate) {
    
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');
    
    if (!tokenHeader || !tokenPayload || !tokenSignature) {
        // If any part of the token is missing, log the user out
        dispatch(Logout(navigate));
        return;
    }
    const token = `${tokenHeader}.${tokenPayload}.${tokenSignature}`;

    const tokenDetails = {
        token
    };    
    dispatch(loginConfirmedAction(tokenDetails));
	

}

import React, { useState, Fragment } from 'react';
import { Modal } from "react-bootstrap";
import { ParamaterService } from '../../../services/ParameterService';
import swal from "sweetalert";

const EditParamModal = ({ isOpen, onClose, data }) => {
    const [formData, setFormData] = useState({
        id: data.id,
        name: data.name,
        value: data.value,
        currency: data.currency
    }); // Initialize formData with only the relevant fields

    const handleInputChange = (e) => {
        const { name, value, currency } = e.target;

        // Only update formData for specified fields
        if (['name','value', 'currency'].includes(name)) {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSave = () => {
        const dataToSend = {
            currency: formData.currency,
            value: formData.value,
            name: formData.name
        };
        console.log('Saving data:', formData);
        ParamaterService.editParameter(formData.id,dataToSend)

        swal("Good job!", "Parameter Edited Successfully!", "success")
        setTimeout(() => {
            window.location.reload();
        }, 2000);
        onClose(); // Close the modal after saving
    };

    return (
        <Fragment>
            <Modal className="modal fade" show={isOpen} onHide={onClose}>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Edit Parameters</h5>
                            <button type="button" className="close" onClick={onClose}><span>&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {/* Form for editing */}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="value">Value</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="value"
                                        name="value"
                                        value={formData.value}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="currency">Currency</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="currency"
                                        name="currency"
                                        value={formData.currency}
                                        onChange={handleInputChange}
                                    />
                                </div>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default EditParamModal;

import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { useNavigate } from 'react-router-dom';
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},

	{
		Header : 'Email Id',
		Footer : 'Email Id',
		accessor: 'user.email',
		Filter: ColumnFilter,
	},
	{
		Header : 'balance',
		Footer : 'balance',
		accessor: 'balance',
		Filter: ColumnFilter,
	},
	{
		Header : 'Date',
		Footer : 'Date',
		accessor: 'createdAt.date',
		Cell: ({ value }) => {return format(new Date(value), 'yyyy-MM-dd H:m:s')},
		Filter: ColumnFilter,
	},

    // {
    //     Header: 'Action',
    //     Footer: 'Action',
    //     accessor: 'Id', // Assuming `id` is the unique identifier for the row
    //     Cell: ({ row }) => {
    //         const navigate = useNavigate();
    //         const handleShow = () => {
    //             navigate(`/wallet-details/${row.original.id}`);
    //         };

    //         return (
    //             <div>
    //                 <button className="btn btn-primary" onClick={handleShow}>Show</button>
    //             </div>
    //         );
    //     },
    //     Filter: ColumnFilter,
    // },

]



export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]
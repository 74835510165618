import React, { useState, Fragment } from 'react';
import { Modal } from "react-bootstrap";
import { ParamaterService } from '../../../services/ParameterService';
import swal from "sweetalert";

const Add = ({ isOpen, onClose, data }) => {
    const [formData, setFormData] = useState(data); // Initialize form data with the passed data

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        ParamaterService.addParameter(formData)                 
        .then((response) => {
            swal("Good job!", "Parameter Added Successfully!", "success")
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
            .catch(error => {
            console.error('Error fetching card data:', error);
            swal("failed!", "Adding parameter failed", "failed")
        })
        onClose();
    };

    return (
        <Fragment>
            <Modal className="modal fade" show={isOpen} onHide={onClose}>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Parameters</h5>
                            <button type="button" className="close" onClick={onClose}><span>&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {/* Form for editing */}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="somvalueeField">Value</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="value"
                                        name="value"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="someField">Currency</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="currency"
                                        name="currency"
                                        onChange={handleInputChange}
                                    />
                                </div>                             
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default Add;
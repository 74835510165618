import React from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import LogoutPage from './Logout';
/// Image
import profile from "../../../images/profile/17.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  var path = window.location.pathname.split("/");
 // var name = path[path.length - 1].split("-");
 /*  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName; */
  return (
    <div className="header">
		<div className="header-content">
			<nav className="navbar navbar-expand">
				<div className="collapse navbar-collapse justify-content-between">
					{/* <div className="header-left">
						<div
							className="dashboard_bar"
							style={{ textTransform: "capitalize" }}
						  >
							{finalName.join(" ").length === 0
							  ? "Dashboard"
							  : finalName.join(" ")}
						</div>
					</div> */}		
					<div className="header-left">
						<div className="dashboard_bar">
							<div className="input-group search-area d-lg-inline-flex d-none">
								<div className="input-group-append">
									<button className="input-group-text"><i className="flaticon-381-search-2"></i></button>
								</div>
								<input type="text" className="form-control" placeholder="Search here..." />
							</div>
						</div>
					</div>

					<ul className="navbar-nav header-right">
						<li className="nav-item">
							<div className="d-flex weather-detail">
								<span><i className="las la-cloud"></i>21</span>
								Medan, IDN
							</div>
						</li>

						<Dropdown as="li" className="nav-item  notification_dropdown">
							<Dropdown.Toggle variant=""  className="nav-link  ai-icon i-false" >
								<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M12.6001 4.3008V1.4C12.6001 0.627199 13.2273 0 14.0001 0C14.7715 0 15.4001 0.627199 15.4001 1.4V4.3008C17.4805 4.6004 19.4251 5.56639 20.9287 7.06999C22.7669 8.90819 23.8001 11.4016 23.8001 14V19.2696L24.9327 21.5348C25.4745 22.6198 25.4171 23.9078 24.7787 24.9396C24.1417 25.9714 23.0147 26.6 21.8023 26.6H15.4001C15.4001 27.3728 14.7715 28 14.0001 28C13.2273 28 12.6001 27.3728 12.6001 26.6H6.19791C4.98411 26.6 3.85714 25.9714 3.22014 24.9396C2.58174 23.9078 2.52433 22.6198 3.06753 21.5348L4.20011 19.2696V14C4.20011 11.4016 5.23194 8.90819 7.07013 7.06999C8.57513 5.56639 10.5183 4.6004 12.6001 4.3008ZM14.0001 6.99998C12.1423 6.99998 10.3629 7.73779 9.04973 9.05099C7.73653 10.3628 7.00011 12.1436 7.00011 14V19.6C7.00011 19.817 6.94833 20.0312 6.85173 20.2258C6.85173 20.2258 6.22871 21.4718 5.57072 22.7864C5.46292 23.0034 5.47412 23.2624 5.60152 23.4682C5.72892 23.674 5.95431 23.8 6.19791 23.8H21.8023C22.0445 23.8 22.2699 23.674 22.3973 23.4682C22.5247 23.2624 22.5359 23.0034 22.4281 22.7864C21.7701 21.4718 21.1471 20.2258 21.1471 20.2258C21.0505 20.0312 21.0001 19.817 21.0001 19.6V14C21.0001 12.1436 20.2623 10.3628 18.9491 9.05099C17.6359 7.73779 15.8565 6.99998 14.0001 6.99998Z" fill="#3E4954"/>
								</svg>
								<span className="badge light text-white bg-primary rounded-circle">12</span>
							</Dropdown.Toggle>
							<Dropdown.Menu align="end" className="mt-2">
							  <PerfectScrollbar className="widget-media dz-scroll p-3 height380 ps">
								<ul className="timeline">
								  <li>
									<div className="timeline-panel">
									  <div className="media me-2">
										<img alt="" width={50} src={avatar} />
									  </div>
									  <div className="media-body">
										<h6 className="mb-1">Dr sultads Send you Photo</h6>
										<small className="d-block">
										  29 July 2020 - 02:26 PM
										</small>
									  </div>
									</div>
								  </li>
								  <li>
									<div className="timeline-panel">
									  <div className="media me-2 media-info">KG</div>
									  <div className="media-body">
										<h6 className="mb-1">
										  Resport created successfully
										</h6>
										<small className="d-block">
										  29 July 2020 - 02:26 PM
										</small>
									  </div>
									</div>
								  </li>
								  <li>
									<div className="timeline-panel">
									  <div className="media me-2 media-success">
										<i className="fa fa-home" />
									  </div>
									  <div className="media-body">
										<h6 className="mb-1">Reminder : Treatment Time!</h6>
										<small className="d-block">
										  29 July 2020 - 02:26 PM
										</small>
									  </div>
									</div>
								  </li>
								  <li>
									<div className="timeline-panel">
									  <div className="media me-2">
										<img alt="" width={50} src={avatar} />
									  </div>
									  <div className="media-body">
										<h6 className="mb-1">Dr sultads Send you Photo</h6>
										<small className="d-block">
										  29 July 2020 - 02:26 PM
										</small>
									  </div>
									</div>
								  </li>
								  <li>
									<div className="timeline-panel">
									  <div className="media me-2 media-danger">KG</div>
									  <div className="media-body">
										<h6 className="mb-1">
										  Resport created successfully
										</h6>
										<small className="d-block">
										  29 July 2020 - 02:26 PM
										</small>
									  </div>
									</div>
								  </li>
								  <li>
									<div className="timeline-panel">
									  <div className="media me-2 media-primary">
										<i className="fa fa-home" />
									  </div>
									  <div className="media-body">
										<h6 className="mb-1">Reminder : Treatment Time!</h6>
										<small className="d-block">
										  29 July 2020 - 02:26 PM
										</small>
									  </div>
									</div>
								  </li>
								</ul>
								<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
								  <div
									className="ps__thumb-x"
									tabIndex={0}
									style={{ left: 0, width: 0 }}
								  />
								</div>
								<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
								  <div
									className="ps__thumb-y"
									tabIndex={0}
									style={{ top: 0, height: 0 }}
								  />
								</div>
							  </PerfectScrollbar>
							  <Link className="all-notification" to="#">
								See all notifications <i className="ti-arrow-right" />
							  </Link>
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown as="li" className="nav-item notification_dropdown ">
							<Dropdown.Toggle
							  variant=""
							  className="nav-link bell bell-link i-false"
							  onClick={() => onNote()}
							>
								<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M25.6666 8.16666C25.6666 5.5895 23.5771 3.5 21 3.5C17.1161 3.5 10.8838 3.5 6.99998 3.5C4.42281 3.5 2.33331 5.5895 2.33331 8.16666V23.3333C2.33331 23.8058 2.61798 24.2305 3.05315 24.4113C3.48948 24.5922 3.99115 24.4918 4.32481 24.1582C4.32481 24.1582 6.59281 21.8902 7.96714 20.517C8.40464 20.0795 8.99733 19.8333 9.61683 19.8333H21C23.5771 19.8333 25.6666 17.7438 25.6666 15.1667V8.16666ZM23.3333 8.16666C23.3333 6.87866 22.2891 5.83333 21 5.83333C17.1161 5.83333 10.8838 5.83333 6.99998 5.83333C5.71198 5.83333 4.66665 6.87866 4.66665 8.16666V20.517L6.31631 18.8673C7.19132 17.9923 8.37899 17.5 9.61683 17.5H21C22.2891 17.5 23.3333 16.4558 23.3333 15.1667V8.16666ZM8.16665 15.1667H17.5C18.144 15.1667 18.6666 14.644 18.6666 14C18.6666 13.356 18.144 12.8333 17.5 12.8333H8.16665C7.52265 12.8333 6.99998 13.356 6.99998 14C6.99998 14.644 7.52265 15.1667 8.16665 15.1667ZM8.16665 10.5H19.8333C20.4773 10.5 21 9.97733 21 9.33333C21 8.68933 20.4773 8.16666 19.8333 8.16666H8.16665C7.52265 8.16666 6.99998 8.68933 6.99998 9.33333C6.99998 9.97733 7.52265 10.5 8.16665 10.5Z" fill="#3E4954"/>
								</svg>		
								<span className="badge light text-white bg-primary rounded-circle">5</span>
							</Dropdown.Toggle>
						</Dropdown>
						<Dropdown as="li" className="nav-item header-profile ">
							<Dropdown.Toggle
							  as="a"
							  to="#"
							  variant=""
							  className="nav-link i-false c-pointer"
							>
								<div className="header-info">
									<span className="text-black">Hello,<strong></strong></span>
									<p className="fs-12 mb-0">Super Admin</p>
								</div>
								<img src={profile} width={20} alt="" />
							</Dropdown.Toggle>
							<Dropdown.Menu align="end" className="mt-2">
							  {/* <Link to="/app-profile" className="dropdown-item ai-icon">
								<svg
								  id="icon-user1"
								  xmlns="http://www.w3.org/2000/svg"
								  className="text-primary"
								  width={18}
								  height={18}
								  viewBox="0 0 24 24"
								  fill="none"
								  stroke="currentColor"
								  strokeWidth={2}
								  strokeLinecap="round"
								  strokeLinejoin="round"
								>
								  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
								  <circle cx={12} cy={7} r={4} />
								</svg>
								<span className="ms-2">Profile </span>
							  </Link> */}
							  {/* <Link to="/email-inbox" className="dropdown-item ai-icon">
								<svg
								  id="icon-inbox"
								  xmlns="http://www.w3.org/2000/svg"
								  className="text-success"
								  width={18}
								  height={18}
								  viewBox="0 0 24 24"
								  fill="none"
								  stroke="currentColor"
								  strokeWidth={2}
								  strokeLinecap="round"
								  strokeLinejoin="round"
								>
								  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
								  <polyline points="22,6 12,13 2,6" />
								</svg>
								<span className="ms-2">Inbox </span>
							  </Link> */}
							  <LogoutPage />
							</Dropdown.Menu>
						</Dropdown>
					</ul>
				</div>
			</nav>
		</div>
    </div>
  );
};

export default Header;

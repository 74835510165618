import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import EditParamModal from './EditParamModal';
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'Name',
		Footer : 'Name',
		accessor: 'name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Value',
		Footer : 'Value',
		accessor: 'value',
		Filter: ColumnFilter,
	},
	{
		Header : 'Currency',
		Footer : 'Currency',
		accessor: 'currency',
		Filter: ColumnFilter,
	},

	{
		Header : 'Created at',
		Footer : 'Created at',
		accessor: 'createdAt.date',
		Cell: ({ value }) => {return format(new Date(value), 'yyyy-MM-dd H:m:s')},
		Filter: ColumnFilter,
	},

    {
        Header: 'Action',
        Footer: 'Action',
        accessor: 'Id', // Assuming `id` is the unique identifier for the row
        Cell: ({ row }) => {
            const navigate = useNavigate();
            const [isModalOpen, setModalOpen] = useState(false);



            const handleEdit = () => {
                setModalOpen(true); // Open the modal
            };

            return (
                <div>
                    <button className="btn btn-secondary ms-2" onClick={handleEdit}>Edit</button>

                    {isModalOpen && (
                        <EditParamModal 
                            isOpen={isModalOpen} 
                            onClose={() => setModalOpen(false)} 
                            data={row.original} // Pass the row data to the modal
                        />
                    )}
                </div>
            );
        },
        Filter: ColumnFilter,
    },

]



export const GROUPED_COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id'
	},
	{
		Header : 'Name',
		Footer : 'Name',
		columns: [
			{
				Header : 'First Name',
				Footer : 'First Name',
				accessor: 'first_name'
			},
			{
				Header : 'Last Name',
				Footer : 'Last Name',
				accessor: 'last_name'
			},
		]
	},
	{
		Header: 'Info',
		Footer: 'Info',
		columns: [
			{
				Header : 'Date of  Birth',
				Footer : 'Date of  Birth',
				accessor: 'date_of_birth'
			},
			{
				Header : 'Country',
				Footer : 'Country',
				accessor: 'country',
			},
			{
				Header : 'Phone',
				Footer : 'Phone',
				accessor: 'phone'
			},
		]
	},
]
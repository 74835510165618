import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { WalletService } from '../../../services/WalletService';
import swal from "sweetalert";

const DepositRequestDetails = () => {
    const { Id } = useParams();
    const [depositData, setDepositData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState("Success");
    const navigate=useNavigate();

    useEffect(() => {
        if (Id) {
            WalletService.getDepositRequestbyId(Id)
                 .then((response) => {
                    console.log('API Response:', response.data); // Check the API response
                    const { data } = response.data;
                    if (data) {
                      console.log('daga:', data);
                        setDepositData(data[0]);                        
                        // Directly set the data object
                    }  else {
                  }
              })
                    .catch(error => {
                    console.error('Error fetching card data:', error);
          
                })
                .finally(() => {
    
                });
        } else {
            console.error('ID is undefined');

        }
    }, [Id]);



    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Add save logic here
        setIsEditing(false);
        const isConfirmed = window.confirm("Are you sure you want to save this edit?");

        const dataToSend = {
          amount: depositData.amount,
          hashTransaction: depositData.hashTransaction,
      };

        if (isConfirmed) {
          WalletService.editRequestDeposit(Id,dataToSend)
          .then(response => {
            console.log('Approval successful:', response);console.log('Approval successful:', dataToSend);
            swal("Good job!", "data edited successfully", "success")
            // window.location.reload();
          })
        .catch(error => {
            console.error('Error approving request:', error);
            swal("Ooops!", "editing data failed", "error")
  
        });
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Optionally reset form data if necessary
    };

    const handleBack = () => {
      navigate("/client-deposit-request");
    };





    const handleApproveRequest = () => {
      const isConfirmed = window.confirm("Are you sure you want to approve this request?");
      if (isConfirmed) {
        WalletService.approveRequestDeposit(Id,{ status: approvalStatus })
        .then(response => {
          swal("Good job!", "Deposit Request Approved Successfully", "success");
          window.location.reload();
        })
      .catch(error => {
          swal("Ooops!", "Deposit Request Approval failed", "error")

      });
      }
  };

    return (

        <Fragment>
         

         { depositData? (
          
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Card request detail</h4>
                <button className="btn btn-secondary" type="button" onClick={handleBack}>Back to list</button>
  
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                      <div className="form-group mb-3 col-md-6">
                        <label>User</label>
                        <input
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`}                        
                          type="text"
                          value={depositData.user.email}
                          disabled
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Amount</label>
                         <input
                          type="text"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={depositData.amount}
                          disabled={!isEditing}
                          onChange={(e) => setDepositData({ ...depositData, amount: e.target.value })}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-6">
                        <label>Currency</label>
                         <input
                          type="text"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={depositData.currency}
                          disabled
                        />
                      </div>                      
                      <div className="form-group mb-3 col-md-6">
                        <label>Transaction Hash</label>
                         <input
                          type="text"
                          className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                          value={depositData.hashTransaction}
                          disabled={!isEditing}
                          onChange={(e) => setDepositData({ ...depositData, hashTransaction: e.target.value })}
                        />
                      </div>
                  </div>
                  <div>
                  {!isEditing ? (
                          <button className="btn btn-primary" type="button" onClick={handleEditClick}>Edit</button>
                      ) : (
                          <>
                              <button className="btn btn-primary" type="button" onClick={handleSaveClick}>Save</button>
                              <button className="btn btn-primary" type="button" onClick={handleCancelClick}>Cancel</button>
                          </>
                      )}
                        {depositData.status === 'Success' ? (
                              <span className="badge bg-success" style={{ float: 'right' }}>Approved</span>
                          ) : (
                              <button className="btn btn-secondary" style={{ float: 'right' }} type="button" onClick={handleApproveRequest}>
                                  Approve Request
                              </button>
                          )}                                  
                        </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
            ):(<div className="">no data</div>)
          }


        </Fragment>
    );
};

export default DepositRequestDetails;

import React, { useState, Fragment } from 'react';
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { WalletService } from '../../../services/WalletService';

const AddWallet = ({ isOpen, onClose, data }) => {
    const [formData, setFormData] = useState(data); // Initialize form data with the passed data

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSave = () => {
        WalletService.addWallet(formData)                 
        .then((response) => {
            swal("Good job!", "Wallet Added Successfully!", "success");
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        })
            .catch(error => {
            console.error('Error fetching card data:', error);
            swal("failed!", "Adding wallet failed", "error")
        })
        onClose();
    };

    return (
        <Fragment>
            <Modal className="modal fade" show={isOpen} onHide={onClose}>
                <div className="modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Add Admin Wallet</h5>
                            <button type="button" className="close" onClick={onClose}><span>&times;</span></button>
                        </div>
                        <div className="modal-body">
                            {/* Form for editing */}
                            <form>
                                <div className="form-group">
                                    <label htmlFor="someField">Value</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="value"
                                        name="value"
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div className="form-group">
                                <label htmlFor="isActive">Active</label>
                                    <select
                                        className="form-control"
                                        id="isActive"
                                        name="isActive"
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select status</option>
                                        <option value="true">Active</option>
                                        <option value="false">Inactive</option>
                                    </select>
                                </div>                             
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger light" onClick={onClose}>Close</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save changes</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
};

export default AddWallet;
import React from 'react';

const IsActiveBadge = ({ status }) => {
    let badgeStyle = {};
    let badgeText = '';

    switch (status) {
        case false:
            badgeStyle = { backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' };
            badgeText = 'inActive';
            break;
        case true:
            badgeStyle = { backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px' };
            badgeText = 'Active';
            break;
        default:
            badgeStyle = { backgroundColor: 'gray', color: 'white', padding: '5px', borderRadius: '5px' };
            badgeText = 'Unknown';
    }

    return <span style={badgeStyle}>{badgeText}</span>;
};

export default IsActiveBadge;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled-input {
    background-color: #e9ecef !important; /* Light grey background */
    cursor: not-allowed; /* Cursor shows it's not editable */
    opacity: 1; /* Full opacity to keep text readable */
}`, "",{"version":3,"sources":["webpack://./src/jsx/components/CardRequestDetail/additcss.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC,EAAE,0BAA0B;IAChE,mBAAmB,EAAE,mCAAmC;IACxD,UAAU,EAAE,uCAAuC;AACvD","sourcesContent":[".disabled-input {\n    background-color: #e9ecef !important; /* Light grey background */\n    cursor: not-allowed; /* Cursor shows it's not editable */\n    opacity: 1; /* Full opacity to keep text readable */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react';

const StatusBadge = ({ status }) => {
    let badgeStyle = {};
    let badgeText = '';

    switch (status) {
        case 'F':
            badgeStyle = { backgroundColor: 'red', color: 'white', padding: '5px', borderRadius: '5px' };
            badgeText = 'Failed';
            break;
        case 'S':
            badgeStyle = { backgroundColor: 'green', color: 'white', padding: '5px', borderRadius: '5px' };
            badgeText = 'Success';
            break;
        case 'P':
            badgeStyle = { backgroundColor: 'orange', color: 'white', padding: '5px', borderRadius: '5px' };
            badgeText = 'Pending';
            break;
        default:
            badgeStyle = { backgroundColor: 'gray', color: 'white', padding: '5px', borderRadius: '5px' };
            badgeText = 'Unknown';
    }

    return <span style={badgeStyle}>{badgeText}</span>;
};

export default StatusBadge;

import axios from 'axios';

const tokenHeader = localStorage.getItem('Header');
const tokenPayload = localStorage.getItem('payload');
const tokenSignature = localStorage.getItem('sig');


const token = `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
console.log(token);
export const WalletService = {

    getAllClientWallet: (page) => {
        // Make an API call to get the card details
        return axios.get(`https://api.paytaps.com/admin/client/wallet/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getAllAdminWallet:(page)=> {
        // Make an API call to get the card details
        return axios.get(`https://api.paytaps.com/admin/address/wallet/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    showWalletAdminById: (Id) => {
        return axios.get(`https://api.paytaps.com/admin/address/wallet/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    
    showClientWalletById: (Id) => {
        return axios.get(`https://api.paytaps.com/admin/client/wallet/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },
    
    editWalletAdminById: (Id, walletData) => {
        return axios.patch(`https://api.paytaps.com/admin/address/wallet/edit/${Id}`, walletData, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    addWallet: (walletData) => {
        return axios.post(`https://api.paytaps.com/admin/address/wallet/add`, walletData, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },



    
    //depositrequest
    getDepositRequest: (page) => {
        // Make an API call to get the card details
        return axios.get(`https://api.paytaps.com/admin/request/deposit/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    getDepositRequestbyId: (Id) => {
        return axios.get(`https://api.paytaps.com/admin/request/deposit/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    editRequestDeposit: (Id, requestData) => {
        return axios.patch(`https://api.paytaps.com/admin/request/deposit/edit/${Id}`, requestData, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },

    approveRequestDeposit: (Id, data) => {
        return axios.patch(`https://api.paytaps.com/admin/request/deposit/approval/${Id}`, data, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },


    //walletbalanceapi
    getMasterBalance(){
        return axios.get(`https://api.paytaps.com/admin/wallet/balance`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    }


};